<template>
  <Navbar />

  <center>
    <h1 class="fw-bold" style="font-size: 9rem">404</h1>
    <p>Halaman yang anda kunjungi tidak ada!</p>
    <p class="mt-5"><router-link to="/product" class="my-auto">Cek Produk Lainnya disini ></router-link></p>
  </center>
</template>

<script>
import Navbar from '@/v2/components/Navbar.vue'

export default {
    components: { Navbar },
    name: 'NotFound'
}
</script>

<style scope>
  center {
    margin: 8vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }
</style>
