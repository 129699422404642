import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

var firebaseConfig = {
    apiKey: "AIzaSyD7rOC25pg9cuHMRPrOYsfoOb8Gho0_fMA",
    authDomain: "beliumkm-93a8c.firebaseapp.com",
    projectId: "beliumkm-93a8c",
    storageBucket: "beliumkm-93a8c.appspot.com",
    messagingSenderId: "295928525036",
    appId: "1:295928525036:web:1c3270cd69db889668b5c9",
    measurementId: "G-0WS5B9FJEV"
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export { messaging }