<template>
    <div v-if="loggedIn" class="__bg-primary py-1">
        <div class="container-fluid px-5">
            <div class="px-5">
                Selamat datang di <strong>beliumkm.co.id</strong>
            </div>
        </div>
    </div>

    <div class="container-fluid px-5">
        <Transition name="slide-fade">
            <div v-if="isSearch" class="pt-4 pb-3 position-absolute bg-white w-100 px-5" style="z-index: 500; top: 0; left: 0;">
                <div class="container-fluid mb-3 d-flex justify-content-between">
                    <div class="text-muted">Apa yang sedang Anda cari?</div>

                    <div class="cursor-pointer" style="margin-right: 0.40rem" @click.prevent="setFocus(false)">
                        <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16992 13.83L13.8299 8.17" stroke="#626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.8299 13.83L8.16992 8.17" stroke="#626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z" stroke="#626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>

                <div class="container-fluid position-relative">
                    <input v-on:keyup.enter="onSearchEnter" class="w-100 px-0 py-2" ref="input" type="text" v-model="search" placeholder="Masukkan keyword produk atau toko..." />

                    <div class="cursor-pointer icon-search" @click.prevent="getSearch()">
                        <svg width="28" height="28" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.1663 32.5C25.9109 32.5 32.9997 25.4112 32.9997 16.6667C32.9997 7.92216 25.9109 0.833332 17.1663 0.833332C8.42183 0.833332 1.33301 7.92216 1.33301 16.6667C1.33301 25.4112 8.42183 32.5 17.1663 32.5Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.6663 34.1667L31.333 30.8333" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </Transition>

        <div class="d-flex justify-content-between align-items-center" style="height: 80px">
            <a href="/" class="ms-5">
                <img :src="require('@/v2/assets/images/logo.svg')">
            </a>

            <div :class="`d-flex gap-3 align-items-center me-5 ${search && 'w-50'}`">
                <input type="text" class="w-100" v-if="search" @focus="setFocus(true)" :value="search">
                <Button :class="`py-2 border d-flex align-items-center`" @click.prevent="setFocus(true)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 22L20 20" stroke="#626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Button>

                <template v-if="!loggedIn">
                    <router-link :class="`btn ${$route.name === 'login' || $route.name === 'resetPassword' ? '__btn-primary' : '__btn-link __text-primary'} px-4`" to="/register/penjual" v-show="$route.name !== 'registerPenjual'">
                        Daftar
                    </router-link>

                    <router-link class="btn __btn-primary px-4" to="/login" v-show="$route.name !== 'login' && $route.name !== 'resetPassword'">Masuk</router-link>
                </template>

                <template v-else>
                    <router-link class="btn btn-link" to="/cart" v-if="user.role === 'Pembeli' || user.role === 'PP'">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.25 22.5C17.2165 22.5 18 21.7165 18 20.75C18 19.7835 17.2165 19 16.25 19C15.2835 19 14.5 19.7835 14.5 20.75C14.5 21.7165 15.2835 22.5 16.25 22.5Z" fill="#626B79"/>
                            <path d="M8.25 22.5C9.2165 22.5 10 21.7165 10 20.75C10 19.7835 9.2165 19 8.25 19C7.2835 19 6.5 19.7835 6.5 20.75C6.5 21.7165 7.2835 22.5 8.25 22.5Z" fill="#626B79"/>
                            <path d="M4.84 3.94L4.64 6.39C4.6 6.86 4.97 7.25 5.44 7.25H20.75C21.17 7.25 21.52 6.93 21.55 6.51C21.68 4.74 20.33 3.3 18.56 3.3H6.27C6.17 2.86 5.97 2.44 5.66 2.09C5.16 1.56 4.46 1.25 3.74 1.25H2C1.59 1.25 1.25 1.59 1.25 2C1.25 2.41 1.59 2.75 2 2.75H3.74C4.05 2.75 4.34 2.88 4.55 3.1C4.76 3.33 4.86 3.63 4.84 3.94Z" fill="#626B79"/>
                            <path d="M20.5101 8.75H5.17005C4.75005 8.75 4.41005 9.07 4.37005 9.48L4.01005 13.83C3.87005 15.54 5.21005 17 6.92005 17H18.0401C19.5401 17 20.8601 15.77 20.9701 14.27L21.3001 9.6C21.3401 9.14 20.9801 8.75 20.5101 8.75Z" fill="#626B79"/>
                        </svg>
                    </router-link>

                    <router-link to="/dashboard/produk" class="btn btn-link text-muted" v-else-if="user.role === 'Pemasok' || user.role === 'Penjual'">
                        <i class="fa-solid fa-boxes-packing"></i>
                    </router-link>

                    <!-- <router-link to="/dashboard/chat" class="btn btn-link">
                    </router-link> -->

                    <div class="dropdown">
                        <Button class="btn btn-link position-relative" id="dropdownMenuButtonNotification" data-bs-toggle="dropdown">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="#626B79"/>
                                <path d="M14.8301 20.01C14.4101 21.17 13.3001 22 12.0001 22C11.2101 22 10.4301 21.68 9.88005 21.11C9.56005 20.81 9.32005 20.41 9.18005 20C9.31005 20.02 9.44005 20.03 9.58005 20.05C9.81005 20.08 10.0501 20.11 10.2901 20.13C10.8601 20.18 11.4401 20.21 12.0201 20.21C12.5901 20.21 13.1601 20.18 13.7201 20.13C13.9301 20.11 14.1401 20.1 14.3401 20.07C14.5001 20.05 14.6601 20.03 14.8301 20.01Z" fill="#626B79"/>
                            </svg>

                            <svg v-if="notifications.count > 0" style="top: 5px; right: 15px;" class="position-absolute" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.5" fill="#F44336" stroke="white"/>
                            </svg>
                        </Button>
                        <ul class="dropdown-menu dropdown-menu-end mt-2" style="width: 380px" aria-labelledby="dropdownMenuButtonNotification">
                            <li class="px-3">
                                <div class="d-flex gap-3 justify-content-between align-items-center text-muted fw-bold">
                                    <div>Notifikasi</div>
                                    <div>{{ notifications.count }}</div>
                                </div>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li v-if="!notifications.count">
                                <div class="chat-description py-2 text-center">
                                    Tidak ada Notifikasi
                                </div>
                            </li>
                            <li v-for="notification in notifications.list">
                                <router-link class="dropdown-item d-flex gap-3 py-2 align-items-center" :to="`/${notification.module_type}`">
                                    <svg v-if="notification.module_type == 'product' || notification.module_type == 'cart'" width="100" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 20%;">
                                        <circle cx="20" cy="20" r="20" fill="#4C2A86"/>
                                        <path d="M13.3333 13.3334H14.4933C15.2133 13.3334 15.78 13.9534 15.72 14.6667L15.1666 21.3067C15.0733 22.3934 15.9333 23.3267 17.0266 23.3267H24.1266C25.0866 23.3267 25.9266 22.54 26 21.5867L26.36 16.5867C26.44 15.48 25.6 14.58 24.4866 14.58H15.88" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M22.8333 26.6667C23.2936 26.6667 23.6667 26.2936 23.6667 25.8333C23.6667 25.3731 23.2936 25 22.8333 25C22.3731 25 22 25.3731 22 25.8333C22 26.2936 22.3731 26.6667 22.8333 26.6667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.5 26.6667C17.9603 26.6667 18.3334 26.2936 18.3334 25.8333C18.3334 25.3731 17.9603 25 17.5 25C17.0398 25 16.6667 25.3731 16.6667 25.8333C16.6667 26.2936 17.0398 26.6667 17.5 26.6667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18 17.3334H26" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <svg v-if="notification.module_type == 'dashboard/chat'" width="100" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 20%;">
                                        <circle cx="20" cy="20" r="20" fill="#0D6EFD"/>
                                        <path d="M23.3333 25.6667H16.6666C14.6666 25.6667 13.3333 24.6667 13.3333 22.3334V17.6667C13.3333 15.3334 14.6666 14.3334 16.6666 14.3334H23.3333C25.3333 14.3334 26.6666 15.3334 26.6666 17.6667V22.3334C26.6666 24.6667 25.3333 25.6667 23.3333 25.6667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M23.3334 18L21.2467 19.6667C20.56 20.2133 19.4334 20.2133 18.7467 19.6667L16.6667 18" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <div class="chat-description" style="width: 80%;">
                                        <div class="mb-0">{{ notification.title }}</div>
                                        <small class="text-muted d-block">{{ notification.message }}</small>
                                    </div>
                                </router-link>
                            </li>
                            <template v-if="notifications.count">
                                <li><hr class="dropdown-divider"></li>
                                <li class="text-center py-2">
                                    <router-link class="__text-primary fw-semibold text-decoration-none" to="/dashboard/notification">
                                        Lihat semua Notifikasi
                                    </router-link>
                                </li>
                            </template>
                        </ul>
                    </div>

                    <div class="dropdown">
                        <Button class="btn btn-link" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" fill="#626B79"/>
                                <path d="M12 14.5C6.99003 14.5 2.91003 17.86 2.91003 22C2.91003 22.28 3.13003 22.5 3.41003 22.5H20.59C20.87 22.5 21.09 22.28 21.09 22C21.09 17.86 17.01 14.5 12 14.5Z" fill="#626B79"/>
                            </svg>
                        </Button>
                        <ul class="dropdown-menu dropdown-menu-end mt-2" style="width: 280px" aria-labelledby="dropdownMenuButton1">
                            <li class="px-3">
                                <div class="d-flex gap-3 py-2 align-items-center">
                                    <img :src="user.role == 'Pembeli' ? user.pembeli?.logo : user.penjual?.logo_usaha" alt="" class="rounded-circle border" width="60" height="60">

                                    <div>
                                        <div class="text-dark fw-semibold">{{ user.name }}</div>
                                        <div class="text-muted">{{ user.alamat?.kota?.name }}</div>
                                    </div>
                                </div>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li>
                                <router-link class="dropdown-item d-flex gap-3 py-2 align-items-center __text-primary fw-semibold __bg-light" to="/dashboard">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.89999 17H18.09C19.99 17 20.99 16 20.99 14.1V2H2.98999V14.1C2.99999 16 3.99999 17 5.89999 17Z" stroke="#4C2A86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2 2H22" stroke="#4C2A86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 22L12 20V17" stroke="#4C2A86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16 22L12 20" stroke="#4C2A86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.5 11L10.65 8.37C10.9 8.16 11.23 8.22 11.4 8.5L12.6 10.5C12.77 10.78 13.1 10.83 13.35 10.63L16.5 8" stroke="#4C2A86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Dashboard</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item d-flex gap-3 py-2 align-items-center" to="/dashboard/notification">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.02 2.91003C8.71003 2.91003 6.02003 5.60003 6.02003 8.91003V11.8C6.02003 12.41 5.76003 13.34 5.45003 13.86L4.30003 15.77C3.59003 16.95 4.08003 18.26 5.38003 18.7C9.69003 20.14 14.34 20.14 18.65 18.7C19.86 18.3 20.39 16.87 19.73 15.77L18.58 13.86C18.28 13.34 18.02 12.41 18.02 11.8V8.91003C18.02 5.61003 15.32 2.91003 12.02 2.91003Z" stroke="#232933" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                                        <path d="M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z" stroke="#232933" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601" stroke="#232933" stroke-width="1.5" stroke-miterlimit="10"/>
                                    </svg>
                                    <span>Notifikasi</span>
                                </router-link>
                            </li>
                            <li>
                                <a class="dropdown-item d-flex gap-3 py-2 align-items-center" href="#" @click.prevent="logout">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54" stroke="#232933" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2 12H14.88" stroke="#232933" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.65 8.65002L16 12L12.65 15.35" stroke="#232933" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Keluar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Button from '@/v2/components/Button';

export default {
    name: 'Navbar',

    components: {
        Button,
    },

    data() {
        return {
            tokenProfileLKPP: this.$route.query.token || this.$route.query.t || localStorage.getItem("tokenProfileLKPP"),
            token: localStorage.getItem("token"),
            loggedIn: localStorage.getItem("loggedIn"),
            loggedInLKPP: localStorage.getItem("loggedInLKPP"),
            user: {
                role: 'Pembeli',
            },
            isSearch: false,
            search: this.$route.query.search,
            notifications: {
                count: 0,
                list: [],
            },
        }
    },

    methods: {
        showModalBannedAccount() {
            const imgError = require('@/v2/assets/images/error/404-page-not-found.png')
            this.$swal({
                icon: 'error',
                showConfirmButton: false,
                allowOutsideClick: false,
                html: `
                    <h3>Akun anda saat ini sedang terkunci</h3>
                    <div class="my-3">
                        Segera selesaikan pembayaran biaya
                        layanan untuk mengaktifkan akun anda.
                    </div>
                    <div>Info lebih lanjut hubungi customer service kami</div>
                `
            })
        },

        onSearchEnter() {
            return this.getSearch();
        },

        setFocus(search) {
            this.isSearch = search;

            if(search) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                })
            }
        },

        getSearch() {
            this.$emit("changeSearch", this.search);
            this.isSearch = false;
            this.$router.push({ path: '/product', query: { search: this.search, page: 1 } });
        },

        logout() {
            axios.post("/api/v1/logout", {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                },
            }).then((resp => {
                this.$swal({
                    icon: 'success',
                    title: resp.data.message,
                });
                localStorage.removeItem('token');
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("loggedInLKPP");
                this.loggedIn = false;
                return this.$router.push({ path: '/login' });
            }));
        },

        getNotification() {
            axios.get(`/api/v1/notification/unread`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            }).then((resp) => {
                this.notifications = resp.data
            })
        },

        readMessage(id) {
            axios.post(`/api/v1/notification/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            }).then((resp) => {
                //
            })
        },
    },

    mounted() {
        /** Digunakan saat sesudah login */
        if (this.loggedInLKPP) {
            localStorage.removeItem("userLKPP");
            localStorage.removeItem("tokenTransactionLKPP");

            axios.get("/api/v1/lkpp/me", {
                headers: {
                    'Authorization': 'Bearer ' + this.tokenProfileLKPP
                }
            }).then((resp) => {
                localStorage.setItem("userLKPP", JSON.stringify(resp.data.user))
                localStorage.setItem("tokenTransactionLKPP", resp.data.user.token)

                this.user = resp.data.user
            })
        }

        if (this.loggedIn) {
            axios.get("/api/user", {
                headers: {
                    Authorization: "Bearer " + this.token
                }
            }).then((resp) => {
                this.user = resp.data
                if(resp.data.is_banned) {
                    this.showModalBannedAccount()
                }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    location.reload();
                }
            });

            this.getNotification()
        }

        /** Digunakan saat pertama kali akses login */
        if(this.tokenProfileLKPP) {
            localStorage.removeItem("userLKPP");
            localStorage.removeItem("tokenTransactionLKPP");

            axios.get("/api/v1/lkpp/me", {
                headers: {
                    'Authorization': 'Bearer ' + this.tokenProfileLKPP
                }
            }).then((resp) => {
                localStorage.setItem("userLKPP", JSON.stringify(resp.data.user))
                localStorage.setItem("tokenTransactionLKPP", resp.data.user.token)
                localStorage.setItem("tokenProfileLKPP", this.tokenProfileLKPP)

                this.user = resp.data.user

                if(this.loggedIn == null || this.loggedIn == undefined) {
                    axios.post("/api/v1/auth/login", {
                        email: resp.data.user.email,
                        user: resp.data.user,
                        force: true,
                    }).then(resp => {
                        localStorage.setItem("token", resp.data.token);
                        localStorage.setItem("loggedIn", true);
                        localStorage.setItem("loggedInLKPP", true);
                        localStorage.setItem("user_role", resp.data.user.role);
                        this.loggedIn = true;
                        this.loggedInLKPP = true;
                    })
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-200px);
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #D0D6DD;
}

input:focus {
  border-color: #292D32;
}

.icon-search {
    position: absolute;
    top: 0;
    right: 15px;
    width: 28px;
}

.chat-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black;
}

::placeholder {
  color: #292D32;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #292D32;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #292D32;
}
</style>
