import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/v2/views/HomePage.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("@/v2/views/Auxilary/AboutUs.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/v2/views/Auth/Login.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/v2/views/Auth/ResetPassword.vue"),
    },
    {
        path: "/reset-password/:code",
        name: "resetPasswordCode",
        component: () => import("@/views/auth/ResetPasswordCode.vue"),
    },
    {
        path: "/register/penjual",
        name: "registerPenjual",
        component: () => import("@/v2/views/Auth/RegisterPenjual.vue"),
    },
    {
        path: "/register/pembeli",
        name: "registerPembeli",
        component: () => import("@/views/auth/RegisterPembeli.vue"),
    },
    {
        path: "/product",
        name: "product",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/pemasok",
        name: "pemasok",
        component: () => import("@/v2/views/Pemasok/Index.vue"),
    },
    {
        path: "/pemasok/:id",
        name: "pemasok-detail",
        component: () => import("@/v2/views/Pemasok/Detail.vue"),
    },
    {
        path: "/toko/:id",
        name: "tokoProduct",
        component: () => import("@/views/product/DetailToko.vue"),
    },
    {
        path: "/product/jasa/akomodasi",
        name: "productJasaAkomodasi",
        component: () => import("@/v2/views/Jasa/Akomodasi.vue"),
    },
    {
        path: "/product/jasa/akomodasi/:id",
        name: "productJasaAkomodasiDetail",
        component: () => import("@/v2/views/Jasa/AkomodasiDetail.vue"),
    },
    {
        path: "/product/jasa/transportasi",
        name: "productJasaTransportasi",
        component: () => import("@/v2/views/Jasa/Transportasi.vue"),
    },
    {
        path: "/product/jasa/transportasi/tiket-pesawat",
        name: "productJasaTransportasiTiketPesawat",
        component: () => import("@/v2/views/Jasa/TiketPesawat.vue"),
    },
    {
        path: "/product/jasa/transportasi/antar-jemput-bandara",
        name: "productJasaTransportasiAntarJemputBandara",
        component: () => import("@/v2/views/Jasa/AntarJemputBandara.vue"),
    },
    {
        path: "/product/jasa/transportasi/sewa-kendaraan",
        name: "productJasaTransportasiSewaKendaraan",
        component: () => import("@/v2/views/Jasa/SewaKendaraan.vue"),
    },
    {
        path: "/product/jasa/transportasi/tiket-bus-travel",
        name: "productJasaTransportasiTiketBusTravel",
        component: () => import("@/v2/views/Jasa/TiketBusTravel.vue"),
    },
    {
        path: "/product/:id",
        name: "detailProduct",
        component: () => import("@/v2/views/Product/Detail.vue"),
    },
    {
        path: "/product/category/:category",
        name: "productCategory",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/cart",
        name: "cart",
        component: () => import("@/v2/views/Transaction/Cart.vue"),
    },
    {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/v2/views/Transaction/Checkout.vue"),
    },
    {
        path: "/pembeli/pesanan-langsung/:id",
        name: "pembeliPesananLangsung",
        component: () =>
            import("@/v2/views/Transaction/Pembeli/PesananLangsung.vue"),
    },
    {
        path: "/penjual/pesanan-langsung/:id",
        name: "pemasokPesananLangsung",
        component: () =>
            import("@/v2/views/Transaction/Pemasok/PesananLangsung.vue"),
    },
    {
        path: "/pembeli/rfq/:id",
        name: "pembeliRfq",
        component: () => import("@/v2/views/Transaction/Pembeli/Rfq.vue"),
    },
    {
        path: "/penjual/rfq/:id",
        name: "penjualRfq",
        component: () => import("@/v2/views/Transaction/Pemasok/Rfq.vue"),
    },
    {
        path: "/transaksi/tagihan/:id",
        name: "pembeliTagihanDetail",
        component: () =>
            import("@/v2/views/Transaction/Pembeli/TagihanDetail.vue"),
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/v2/views/Dashboard/Dashboard.vue"),
    },
    {
        path: "/dashboard/notification",
        name: "dashboardNotification",
        component: () => import("@/v2/views/Dashboard/Notification.vue"),
    },
    {
        path: "/dashboard/produk",
        name: "dashboardProduk",
        component: () => import("@/v2/views/Dashboard/Product/Product.vue"),
    },
    {
        path: "/dashboard/tambah-produk",
        name: "dashboardTambahProduk",
        component: () => import("@/v2/views/Dashboard/Product/AddProduct.vue"),
    },
    {
        path: "/dashboard/edit-produk/:id",
        name: "dashboardEditProduk",
        component: () => import("@/v2/views/Dashboard/Product/EditProduct.vue"),
    },
    {
        path: "/dashboard/saldo",
        name: "dashboardSaldo",
        component: () => import("@/views/auth/dashboard/penjual/Saldo.vue"),
    },
    {
        path: "/dashboard/transaksi/pesanan",
        name: "dashboardTransaksiPesanan",
        component: () =>
            import("@/v2/views/Dashboard/Transaksi/TransaksiPesanan.vue"),
    },
    {
        path: "/dashboard/history/rfq",
        name: "dashboardHistoryRfq",
        component: () =>
            import("@/views/auth/dashboard/penjual/transaksi/Rfq.vue"),
    },
    {
        path: "/dashboard/transaksi/riwayat-pinjaman",
        name: "dashboardHistoryRiwayatPinjaman",
        component: () =>
            import(
                "@/v2/views/Dashboard/Transaksi/TransaksiRiwayatPeminjaman.vue"
            ),
    },
    {
        path: "/dashboard/transaksi/tagihan-biaya-layanan",
        name: "dashboardTagihanBiayaLayanan",
        component: () =>
            import(
                "@/v2/views/Dashboard/Transaksi/TransaksiTagihanBiayaLayanan.vue"
            ),
    },
    {
        path: "/dashboard/kontak",
        name: "dashboardKontak",
        component: () => import("@/v2/views/Dashboard/ContactUs.vue"),
    },
    {
        path: "/dashboard/pembeli/lacak-pengiriman",
        name: "dashboardLacakPengiriman",
        component: () =>
            import("@/views/auth/dashboard/pembeli/LacakPengiriman.vue"),
    },
    {
        path: "/dashboard/manajemen-akun",
        name: "dashboardManajemenAkun",
        component: () =>
            import("@/v2/views/Dashboard/Pembeli/ManajemenAkun.vue"),
    },
    {
        path: "/dashboard/manajemen-akun/:id",
        name: "dashboardEditAkun",
        component: () =>
            import("@/v2/views/Dashboard/Pembeli/EditAkun.vue"),
    },
    {
        path: "/dashboard/tambah-akun",
        name: "dashboardTambahAkun",
        component: () =>
            import("@/v2/views/Dashboard/Pembeli/TambahAkun.vue"),
    },
    {
        path: "/dashboard/edit-profile",
        name: "dashboardEditProfile",
        component: () => import("@/v2/views/Dashboard/EditProfile.vue"),
    },
    {
        path: "/dashboard/document",
        name: "dashboardDocument",
        component: () => import("@/v2/views/Dashboard/UnduhDocument.vue"),
    },
    {
        path: "/dashboard/pembeli/transaksi/rfq",
        name: "dashboardPembeliRfq",
        component: () =>
            import("@/views/auth/dashboard/pembeli/transaksi/Rfq.vue"),
    },
    {
        path: "/dashboard/pembeli/transaksi/tagihan",
        name: "dashboardPembeliTagihan",
        component: () =>
            import("@/v2/views/Dashboard/Transaksi/TransaksiTagihan.vue"),
    },
    {
        path: "/dashboard/pembeli/transaksi/tagihan/:id",
        name: "dashboardPembeliTagihanDetail",
        component: () =>
            import(
                "@/views/auth/dashboard/pembeli/transaksi/TagihanDetail.vue"
            ),
    },
    {
        path: "/dashboard/transaksi/penjual/riwayat-pinjam",
        name: "dashboardPemasokRiwayatPinjam",
        component: () =>
            import(
                "@/views/auth/dashboard/penjual/transaksi/RiwayatPinjam.vue"
            ),
    },
    {
        path: "/dashboard/transaksi/penjual/tagihan",
        name: "dashboardPemasokTagihan",
        component: () =>
            import("@/views/auth/dashboard/penjual/transaksi/Tagihan.vue"),
    },
    {
        path: "/dashboard/penjual/edit-profile",
        name: "dashboardPemasokEditProfile",
        component: () =>
            import("@/views/auth/dashboard/penjual/EditProfile.vue"),
    },
    {
        path: "/dashboard/chat",
        name: "dashboardChatRoom",
        component: () => import("@/v2/views/Dashboard/ChatRoom.vue"),
    },
    {
        path: "/syarat-ketentuan",
        name: "syaratKetentuan",
        component: () => import("@/v2/views/Auxilary/SyaratKetentuan.vue"),
    },
    {
        path: "/kebijakan-privasi",
        name: "kebijakanPrivasi",
        component: () => import("@/v2/views/Auxilary/KebijakanPrivasi.vue"),
    },
    {
        path: "/contact",
        name: "contactUs",
        component: () => import("@/v2/views/Auxilary/NewContactUs.vue"),
    },
    {
        path: "/biaya-layanan",
        name: "biayaLayanan",
        component: () => import("@/v2/views/Auxilary/BiayaLayanan.vue"),
    },
    {
        path: "/cara-berbelanja",
        name: "caraBerbelanja",
        component: () => import("@/v2/views/Auxilary/CaraBelanja.vue"),
    },
    {
        path: "/cara-berjualan",
        name: "caraBerjualan",
        component: () => import("@/v2/views/Auxilary/CaraJualan.vue"),
    },
    {
        path: "/news/detail",
        name: "newsDetail",
        component: () => import("@/views/DetailNews.vue"),
    },

    // LKPP Akses
    {
        path: "/lkpp/product/makanan",
        name: "categoryFood",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/furnitur",
        name: "categoryFurniture",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/alat-tulis-kantor",
        name: "categoryATK",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/suvenir",
        name: "categorySuvenir",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/fashion",
        name: "categoryFashion",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/perkakas",
        name: "categoryPerkakas",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/alat-kesehatan",
        name: "categoryAlkes",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/elektronik",
        name: "categoryElektronik",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/sewa-alat",
        name: "categorySewaAlat",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/jasa/transportasi",
        name: "categoryTransportasi",
        component: () => import("@/v2/views/Jasa/Transportasi.vue"),
    },
    {
        path: "/lkpp/product/jasa/akomodasi",
        name: "categoryAkomodasi",
        component: () => import("@/v2/views/Jasa/Akomodasi.vue"),
    },
    {
        path: "/lkpp/product/jasa/kreatif",
        name: "categoryJasaKreatif",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/pertanian-peternakan",
        name: "categoryPertanianPeternakan",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/lkpp/product/:slug/:id/details",
        name: "detailProductLkpp",
        component: () => import("@/views/lkpp/product/Detail.vue"),
    },
    {
        path: "/lkpp/dashboard",
        name: "dashboardLkpp",
        component: () => import("@/views/auth/dashboard/Dashboard.vue"),
    },
    {
        path: "/lkpp/product/:pathMatch(.*)*",
        name: "categoryProductOther",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/v2",
        name: "homev2",
        component: () => import("@/v2/views/HomePage.vue"),
    },
    {
        path: "/v2/product",
        name: "productv2",
        component: () => import("@/v2/views/Product/Index.vue"),
    },
    {
        path: "/v2/product/:id",
        name: "productv2-detail",
        component: () => import("@/v2/views/Product/Detail.vue"),
    },
    {
        path: "/v2/pemasok",
        name: "pemasokv2",
        component: () => import("@/v2/views/Pemasok/Index.vue"),
    },

    // Not Found Route
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
    },
];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
