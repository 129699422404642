<template>
    <section class="my-3">
        <div class="d-flex justify-content-between title">
            <h5>Liputan Media & Kerjasama</h5>
        </div>
        <div class="row my-2">
            <div class="col-lg-3 col-6 mb-3" v-for="item in 3" v-bind:key="item">
                <NewsCard :image="require('@/assets/images/news-img.png')"
                    :title="'Gandeng Ratusan Brand Lokal, Jakcloth Kembali Gelar Pameran di 3 Kota Besar'"
                    :description="'Sempat fakum menggelar pameran offline karena pandemi Covid-19, pameran Jakcloth 2021 pada tahun ini kembali hadir. Pameran tersebut menghadirkan ratusan brand lokal hasil karya anak bangsa untuk unjuk produk terbaiknya dalam ajang tersebut.'" />
            </div>
        </div>
    </section>
</template>

<script>
import NewsCard from '../card/NewsCard.vue';
export default {
    name: "Liputan",
    components: { NewsCard }
}
</script>
