<template>
  <Navbar />

  <div class="container">
    <div class="row align-items-center wrapper-banner">
      <div class="col-lg-9">
        <swiper :slidesPerView="1" :spaceBetween="30" :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }" :pagination="{
            clickable: true,
        }" :navigation="false" :modules="modules" class="mySwiper">
          <swiper-slide>
            <img :src="require('@/assets/images/slide-9.jpg')" class="img-fluid d-block">
          </swiper-slide>
          <swiper-slide>
            <img :src="require('@/assets/images/slide-1.jpg')" class="img-fluid d-block">
          </swiper-slide>
          <swiper-slide>
            <img :src="require('@/assets/images/slide-2.jpg')" class="img-fluid d-block">
          </swiper-slide>
          <swiper-slide>
            <img :src="require('@/assets/images/slide-3.jpg')" class="img-fluid d-block">
          </swiper-slide>
        </swiper>
        <!-- <img :src="require('@/assets/images/home-big.png')" class="img-fluid"> -->
      </div>
      <div class="col-lg-3 mt-2 mt-lg-0">
        <div class="row gap-lg-4">
          <div class="col-lg-12 col-6">
            <img :src="require('@/assets/images/banner/1-banner.jpg')" class="img-fluid">
          </div>
          <div class="col-lg-12 col-6">
            <img :src="require('@/assets/images/banner/2-banner.jpg')" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

    <Category />

    <img :src="require('@/assets/images/slide-8.jpg')" class="img-fluid my-3 w-100">

    <Product />

    <Partner />

    <!-- <Liputan /> -->

  </div>
</template>

<script>
import Category from '../components/home/Category.vue';
import Product from '../components/home/Product.vue';
import Partner from '../components/home/Partner.vue';
import Liputan from '../components/home/Liputan.vue';

import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

import Navbar from '@/v2/components/Navbar.vue';

export default {
  name: "HomeView",
  components: { Category, Product, Partner, Liputan, Swiper, SwiperSlide, Navbar },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },

  data() {
    return {
      token: localStorage.getItem("token")
    }
  }
}
</script>

<style scope>
.wrapper-banner {
  padding: 25px 0;
}

.swiper-slide {
  width: 100%;
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}
</style>
