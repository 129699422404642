<template>
    <router-link :to="to ?? 'product'" style="text-decoration:none;">
        <div class="__card-category">
            <div class="img">
                <img :src="image" class="img-fluid">
            </div>
            <div class="capt mt-2">
                <p>{{ title }}</p>
            </div>
        </div>
    </router-link>
</template>


<script>
export default {
    name: "CategoryCard",
    props: ['image', 'title', 'to'],
}
</script>

<style scope>
.__card-category {
    background-color: white;
    border-radius: 15px;
}

.__card-category .capt {
    padding: 10px;
}

.__card-category img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.__card-category .capt p {
    margin: 0;
    font-size: 14px;
    color: var(--primary-color);
    text-align: center;
}
</style>
