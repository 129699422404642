<template>
    <div class="footer py-5 mb-4 text-center text-md-start">
        <div class="container-fluid px-5">
            <div class="row">
                <div class="col-lg-3 mt-4" style="margin-top: -20px;">
                    <router-link to="/">
                        <img :src="require('@/assets/images/icon/logo.svg')" class="img-fluid mb-3">
                    </router-link>

                    <div class="text-muted">
                        <strong>BeliUMKM</strong> merupakan ritel daring yang bertujuan untuk melayani kebutuhan belanja barang/jasa dari instansi pemerintah selaku pihak pembeli dengan melibatkan pelaku usaha mikro dan kecil selaku pihak pemasok.
                    </div>
                </div>

                <div class="col-lg-3 ps-md-5 mt-4">
                    <div class="title fw-semibold fs-5 mb-3">Bantuan</div>

                    <ul class="menu p-0">
                        <li><router-link :class="$route.name === 'caraBerbelanja' ? 'active-link' : ''" to="/cara-berbelanja">Cara Berbelanja</router-link></li>
                        <li><router-link :class="$route.name === 'caraBerjualan' ? 'active-link' : ''" to="/cara-berjualan">Cara Berjualan</router-link></li>
                        <li><router-link :class="$route.name === 'contactUs' ? 'active-link' : ''" to="/contact">Hubungi Kami</router-link></li>
                        <li><router-link :class="$route.name === 'kebijakanPrivasi' ? 'active-link' : ''" to="/kebijakan-privasi">Kebijakan Privasi</router-link></li>
                        <li><router-link :class="$route.name === 'syaratKetentuan' ? 'active-link' : ''" to="/syarat-ketentuan">Syarat & Ketentuan</router-link></li>
                        <li><router-link :class="$route.name === 'about' ? 'active-link' : ''" to="/about">Tentang Kami</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class="title fw-semibold fs-5 mb-3">Temukan Kami</div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3">
                        <img :src="require('@/assets/images/icon/social-media/facebook.svg')" width="50">
                        <img :src="require('@/assets/images/icon/social-media/instagram.svg')" width="50">
                        <img :src="require('@/assets/images/icon/social-media/youtube.svg')" width="50">
                    </div>

                    <div class="title fw-semibold fs-5 mb-3 mt-4">Metode Pembayaran</div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/payments/mastercard.svg')" width="40">
                        <img :src="require('@/assets/images/icon/payments/jcb.svg')" width="40">
                        <img :src="require('@/assets/images/icon/payments/visa.svg')" width="80">
                        <img :src="require('@/assets/images/icon/payments/bank-transfer.svg')" width="40">
                    </div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/payments/qris.svg')" width="100">
                        <img :src="require('@/assets/images/icon/payments/virtual-account.svg')" width="50">
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class="title fw-semibold fs-5 mb-3">Metode Pengiriman</div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/kurir/SiCepat-Ekspres.png')" width="100">
                        <img :src="require('@/assets/images/icon/kurir/J&T-Express.png')" width="100">
                    </div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/kurir/Lion-Parcel.png')" width="100">
                        <img :src="require('@/assets/images/icon/kurir/TiKi.png')" width="100">
                    </div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/kurir/Indah-logistik.png')" width="80">
                        <img :src="require('@/assets/images/icon/kurir/JNE-Express.png')" width="70">
                    </div>

                    <div class="d-flex my-3 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/kurir/Ninja-Xpress.png')" width="80">
                        <img :src="require('@/assets/images/icon/kurir/SAP-Express.png')" width="50">
                        <img :src="require('@/assets/images/icon/kurir/satu kurir.png')" width="30">
                    </div>
                    <div class="d-flex mt-5 justify-content-center justify-content-md-start gap-3 align-items-center">
                        <img :src="require('@/assets/images/icon/lkpp.svg')" width="230">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer class="text-center py-4">
        <span class="d-block">Copyright &copy; {{ year == 2022 ? year : `2022 - ${year}` }}, <a href="https://beliumkm.co.id" class="text-white text-decoration-none">BeliUMKM</a> • All rights reserved.</span>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
footer {
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
}

footer span {
    text-align: center;
    font-weight: 300;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #8B95A5;
}

.footer {
    background-color: #F6F6F6;
    padding-top: 126px;
    padding-bottom: 241px;
    /* padding: 126px 161px 241px 161px; */
    /* padding: 45px 161px 45px 161px; */
}

.footer ul.menu {
    list-style-type: none;
}

.footer ul li {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
}

.footer ul li a {
    text-decoration: none;
    color: #000000;
}

.footer ul li a:hover {
    text-decoration: none;
    color: #4b4b4b;
}
</style>
