<template>
    <div class="__card-product">
        <div class="img">
            <img :src="image" class="img-fluid d-block mx-auto">
        </div>
        <div class="card-body">
            <h6>{{ title }}</h6>
            <p>{{ description }}</p>
            <router-link to="/news/detail">Selengkapnya</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductCard",
    props: ['image', 'title', 'description'],
}
</script>

<style scope>
.__card-product {
    border: 1px solid #B0B0B0;
    border-radius: 12px;
}


.__card-product .img img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.__card-product .capt p {
    margin: 0;
    font-size: 14px;
    color: var(--primary-color);
    text-align: center;
}

.__card-product .card-body {
    padding: 10px;
}

.__card-product .card-body h6 {
    margin: 0;
}

.__card-product .card-body h6:first-child {
    font-size: 16px;
    font-weight: 500;
}

.__card-product .card-body p {
    font-size: 13px;
    color: #505E6B;
    font-weight: 400;
}

.__card-product .card-body a {
    display: block;
    color: var(--primary-color);
    font-weight: 500;
    text-decoration: none;
}
</style>
