<template>
    <section class="my-3">
        <b-tabs content-class="mt-3" nav-wrapper-class="__nav-pills" active-nav-item-class="cat-active-pills"
            :pills="true">
            <b-tab title="Komoditas Barang" active>
                <div class="__card-cat">
                    <div class="row">
                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Alat Kesehatan'"
                                :image="require('@/assets/images/icon/alat-kesehatan.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Alat Tulis Kantor'"
                                :image="require('@/assets/images/icon/alat-tulis-kantor.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Elektronik'" :image="require('@/assets/images/icon/elektronik.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Fashion'" :image="require('@/assets/images/icon/fashion.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Furniture'" :image="require('@/assets/images/icon/furniture.png')">
                            </CategoryCard>
                        </div>
                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Makanan dan Minuman'"
                                :image="require('@/assets/images/icon/makan-dan-minum.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Perkakas'" :image="require('@/assets/images/icon/perkakas.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Pertanian dan Kehutanan'"
                                :image="require('@/assets/images/icon/perkebunan.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Souvenir'" :image="require('@/assets/images/icon/souvenir.png')">
                            </CategoryCard>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Komoditas Jasa">
                <div class="__card-cat">
                    <div class="row">
                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :to="'product/jasa/akomodasi'" :title="'Akomodasi'"
                                :image="require('@/assets/images/icon/akomodasi.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Jasa Kreatif Kebutuhan Kantor'"
                                :image="require('@/assets/images/icon/jasa-kreatif-kebutuhan-kantor.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :to="'product/jasa/transportasi'" :title="'Jasa Transportasi'"
                                :image="require('@/assets/images/icon/jasa-transportasi.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Kurir'" :image="require('@/assets/images/icon/jasa-kurir.png')">
                            </CategoryCard>
                        </div>

                        <div class="col-lg-2 col-6 mb-3">
                            <CategoryCard :title="'Sewa Peralatan dan Ruangan'"
                                :image="require('@/assets/images/icon/sewa-peralatan-dan-ruangan.png')">
                            </CategoryCard>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </section>


</template>

<script>
import CategoryCard from '../card/CategoryCard.vue'
export default {
    components: {
        CategoryCard
    }
}
</script>

<style scope>
.__card-cat {
    background-color: #F6F6F6;
    border-radius: 15px;
    padding: 40px;
}

.__nav-pills .nav-item {
    margin-right: 10px;
}
</style>
