<template>
    <section class="my-3">
        <div class="d-flex justify-content-between title">
            <h5>Partner Kami</h5>
        </div>
        <!-- <div class="row my-2">
            <div class="col-lg-2 col-6 mb-3" v-for="item in 6" v-bind:key="item">
                <div class="__card-product">
                    <div class="img">
                        <img :src="require('@/assets/images/partner.png')" class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </div>
        </div> -->
        <swiper :slidesPerView="6" :spaceBetween="30" :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }" :pagination="{
            clickable: true,
        }" :navigation="false" :modules="modules" class="mySwiper mb-2">
            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/partner.png')" class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/partner-2.png')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/partner-3.png')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/partner-4.png')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/partner-5.png')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/Logo_Kabupaten_Bone_Bolango.jpg')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide>
                <div class="card">
                    <div class="card-body">
                        <img :src="require('@/assets/images/LOGO_KOTA_GORONTALO.png')"
                            class="img-fluid d-block mx-auto p-3 w-logo">
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper";

export default {
    name: "Partner",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay, Navigation],
        };
    },
}
</script>

<style scope>
.__card-product {
    border: 1px solid #B0B0B0;
    border-radius: 15px;
}

.w-logo {
    height: 130px;
}
</style>
