<template>
    <button :class="`btn ${type}`">
        <slot />
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        type: String,
    }
}
</script>

<style scoped>
.btn {
    border-radius: 4px;
}
.link {
    border: none;
    color: #4C2A86;
}
.link:hover,
.link:focus,
.link:active {
    color: #2a0b60;
    text-decoration: underline;
}
.primary {
    background-color: #4C2A86;
    color: #ffffff;
}
.outline-primary {
    color: #4C2A86;
}
.primary,
.outline-primary {
    border: 1px solid #4C2A86;
}
.primary:hover,
.primary:focus,
.primary:active,
.outline-primary:hover,
.outline-primary:focus,
.outline-primary:active {
    background-color: #3b1a7580;
    color: #2a0b60;
    border: 1px solid #2a0b60;
}
.secondary {
    color: #ffffff;
    background-color: #0D6EFD;
}
.outline-secondary {
    color: #0D6EFD;
}
.secondary,
.outline-secondary {
    border: 1px solid #0D6EFD;
}
.secondary:hover,
.secondary:focus,
.secondary:active,
.outline-secondary:hover,
.outline-secondary:focus,
.outline-secondary:active {
    background-color: #0a459d80;
    color: #0a459d;
    border: 1px solid #0a459d;
}
.gray {
    color: #ffffff;
    background-color: #D0D6DD;
}
.outline-gray {
    color: #D0D6DD;
}
.gray,
.outline-gray {
    border: 1px solid #D0D6DD;
}
.gray:hover,
.gray:focus,
.gray:active,
.outline-gray:hover,
.outline-gray:focus,
.outline-gray:active {
    background-color: #D0D6DD80;
    color: #D0D6DD;
    border: 1px solid #D0D6DD;
}
</style>
