<template>
    <router-link :to="'/product/' + slug + '/' + id + '/details'" style="text-decoration:none">
        <div class="__card-product">
            <div class="img">
                <img :src="image" class="img-fluid d-block mx-auto w-100">
            </div>
            <div class="card-body">
                <h6>{{ title }}</h6>
                <span>{{ toko }}</span>
                <div class="text-price fs-5">Rp {{ price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</div>
                <span class="mt-2 d-block">{{ lokasi_penjual }}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ProductCard",
    props: ['image', 'title', 'toko', 'price', 'slug', 'id', 'lokasi_penjual'],
}
</script>

<style scope>
.__card-product {
    border: 1px solid #B0B0B0;
    border-radius: 15px;
    height: 330px;
    overflow: hidden;
}

.__card-product .img {
    border-bottom: 1px solid #B0B0B0;
}

.__card-product .img img {
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    height: 200px;
    object-fit: scale-down;
}


.__card-product .capt p {
    margin: 0;
    font-size: 14px;
    color: var(--primary-color);
    /* text-align: center; */
}

.__card-product .card-body {
    padding: 10px;
    /* text-align: center; */
}

.__card-product .text-price {
    color: var(--primary-color)
}

.__card-product .card-body h6 {
    margin: 0;
}

.__card-product .card-body h6:first-child {
    font-size: 15px;
    font-weight: 400;
    color: black;
}

.__card-product .card-body span {
    font-size: 14px;
    color: #505E6B;
    font-weight: 400;
}

.__card-product .card-body h6:nth-child(3) {
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-color)
}
</style>
