<template>
    <section class="mt-3 mb-2">
        <div class="d-flex justify-content-between title">
            <h5>Rekomendasi Produk</h5>
            <router-link to="/product" class="my-auto">Lihat Semua Produk ></router-link>
        </div>
        <div class="row mb-2">
            <b-skeleton-wrapper :loading="loading">
                <template #loading>
                    <div v-for="i in 4" :key="i" class="col-lg-3 col-6 mb-3">
                        <div class="__card-product">
                            <div class="img">
                                <b-skeleton height="100%"></b-skeleton>
                            </div>
                            <div class="card-body">
                                <b-skeleton width="100%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="col-lg-3 col-6 mb-3" v-for="item in product" v-bind:key="item">
                    <ProductCard :slug="item.slug" :id="item.id" :image="item.image[0]" :title="item.title" :toko="item.nama_toko" :price="item.show_price" :lokasi_penjual="item.lokasi_penjual" />
                </div>
            </b-skeleton-wrapper>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import ProductCard from '../card/ProductCard.vue';
export default {
    name: "Product",
    data() {
        return {
            loading: true,
            product: [],
        }
    },
    mounted() {
        axios.get("/api/v1/product", {
            params: {
                limit: 8
            }
        }).then((resp) => {
            this.product = resp.data.data;
            this.loading = false;
        })
    },
    components: { ProductCard }
}
</script>
