import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "vue-select/dist/vue-select.css";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import axios from "axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Toaster from "@meforma/vue-toaster";
import "@/assets/css/style.css";
import "animate.css";
import { messaging } from "./firebase";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { vMaska } from "maska";

import { inject } from "@vercel/analytics";

/** Jika Bukan localhost */
if (!process.env.VUE_APP_IS_LOCALHOST) {
    inject();
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
    process.env.VUE_APP_ENDPOINT_API || "https://api.beliumkm.com";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
    "token"
)}`;

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register(process.env.VUE_APP_ENDPOINT + "/firebase-messaging-sw.js")
        .then((reg) => {
            console.log(`Service Worker Registration (Scope: ${reg.scope})`);
        })
        .catch((error) => {
            const msg = `Service Worker Error (${error})`;
            console.log("error ", error);
        });
} else {
    // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
    console.warn("Service Worker not available");
}

const app = createApp(App);

app.directive("maska", vMaska);

app.use(router);
app.use(BootstrapVue3);
app.use(VueSweetalert2);
app.use(Toaster);
app.use(LoadingPlugin);
app.use(VueGoogleMaps, {
    load: {
        key:
            process.env.VUE_APP_GOOGLE_MAP_API ||
            "AIzaSyCAN2Gau9chF46UpvPhbzxokc0DWur3weA",
        libraries: "places",
        installComponents: true,
    },
});
app.config.globalProperties.$isLocalhost =
    process.env.VUE_APP_IS_LOCALHOST || false;
app.config.globalProperties.$messaging = messaging;
app.mount("#app");
